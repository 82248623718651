<template>
    <div>
        <div style="padding:0px 15px 15px 15px;background:#fff">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item label="">
                            <a-tooltip title="发起人">
                            <a-select style="width: 140px" v-model='searchParams.search.created_by' @search='handleSearch' allowClear showSearch placeholder="发起人" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip title="访诺时间">
                                <a-range-picker
                                :ranges="rangesData"
                                v-model='searchDatas.promise'
                                allowClear
                                @change="(val,time)=>handleTime(val,time,'promise')" style="width: 240px"/>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="journal_id"
                    :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="created_by" slot-scope="text, record , index">
                        <span>{{text.employee_name}}</span>
                    </template>
                    <template slot="employee" slot-scope="text, record , index">
                        <span v-if="record.nameList.length === 0">所有人</span>
                        <a-dropdown v-else>
                            <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                             {{record.nameList[0]}}等{{record.nameList.length}}人<a-icon type="caret-down" />
                            </div>
                            <a-menu slot="overlay">
                                <a-menu-item v-for="(it,j) in record.nameList" :key="j">
                                    {{it}}
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  {title: '汇报时间', dataIndex: 'created_at', key: 'created_at',align:"center"},
  {title: '汇报人', dataIndex: 'created_by', key: 'created_by',align:"center",scopedSlots: { customRender: 'created_by' }},
  {title: '汇报部门', dataIndex: 'employee_department', key: 'employee_department',align:"center"},
  {title: '汇报对象', width:'120px', dataIndex: 'read_employee_ids', key: 'read_employee_ids',align:"center",scopedSlots: { customRender: 'employee' },ellipsis: true},
]
    import moment from 'moment'
    import tableMixins from '@/common/mixins/table'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'commitRecord',
        data() {
            return {
                columns,
                loading: false,
                exportLoading: false,
                list:[],
                staffList:[],
                searchDatas:{},
            }
        },
        mixins: [ tableMixins , ranges],
        created () {
            this.getStaff()
        },
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('journalRecordAction',this.searchParams)
                .then(res=>{
                    res.items.forEach(item=>{
                        let arr = []
                        item.send_employee_ids.forEach(it=>{
                            arr.push(it.employee_name)
                        })
                        item.nameList = arr
                        item.nameStr = arr.join(',')
                    })
                    this.list = res.items
                    this.pageParams = res._meta
                })
                this.loading = false
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search['start_date'] = time[0]
                this.searchParams.search['end_date'] = time[1]
            },
            handleSearch(val){
                this.getStaff(val)
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search
                }
                let res = await this.$store.dispatch('journalExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `提交记录.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>