<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>工作台</a-breadcrumb-item>
            <a-breadcrumb-item>日报</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="journal_main-box">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane :key="0">
                    <span slot="tab">全部</span>
                </a-tab-pane>
                <a-tab-pane :key="1">
                    <span slot="tab">我未读</span>
                </a-tab-pane>
                <a-tab-pane :key="2">
                    <span slot="tab">我发的</span>
                </a-tab-pane>
                <a-tab-pane :key="3">
                    <span slot="tab">我收的</span>
                </a-tab-pane>
                <a-tab-pane :key="4">
                    <span slot="tab">提交记录</span>
                </a-tab-pane>
            </a-tabs>
            <div v-if="activeKey != 4" class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="plus" type="dashed" @click="showEditModal()">写日报</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item label="">
                            <a-tooltip title="发起人">
                            <a-select style="width: 140px" v-model='searchParams.search.created_by' @search='handleSearch' allowClear showSearch placeholder="发起人" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item label="">
                            <a-tooltip title="提交时间">
                            <a-range-picker
                            :ranges="rangesData"
                            v-model='searchDatas.submit'
                            allowClear
                            @change="(val,time)=>handleTime(val,time,'submit')" style="width: 240px" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item label="">
                            <a-tooltip title="日报类型">
                            <a-select style="width: 140px" v-model='searchParams.search.category_id' allowClear placeholder="日报类型">
                            <a-select-option v-for="(d, index) of journalType" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
            v-if="activeKey != 4"
            class="journal-box"
            @scroll="handleScroll"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            >
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <div class="journal-item">
                            <div class="journal-item-title">
                                <img :src="item.created_by.employee_avatar">
                                <div>
                                    <div>
                                        <span>{{item.created_by.employee_name}}</span>
                                        <span style="color:#ccc;font-size:12px;margin-left:5px">{{item.is_read === 0?'未读':'已读'}}</span>
                                    </div>
                                    <div>
                                        <span style="color:#ccc;font-size:12px;margin-left:5px">{{item.created_at}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="journal-item-content">
                                <div class="journal-item-bottom">{{item.title}}</div>
                                 <a-row type="flex">
                                    <a-col :span="6">
                                        <span>校总跟:</span> {{item.follow_total.studio_follow_total}}
                                    </a-col>
                                    <a-col :span="6">
                                        <span>校总诺:</span> {{item.follow_total.studio_promise_total}}
                                    </a-col>
                                    <a-col :span="6">
                                        <span>个总跟:</span> {{item.follow_total.employee_follow_total}}
                                    </a-col>
                                    <a-col :span="6">
                                        <span>个总诺:</span> {{item.follow_total.employee_promise_total}}
                                    </a-col>
                                </a-row>

                                <a-row v-if="item.channel_follow_list && item.channel_follow_list.length>0">
                                    <a-col class="journal-item-bottom" :span="4">
                                        <span>跟进渠道:</span>
                                    </a-col>
                                    <a-col :span="18">
                                        <div v-for="(it,j) in item.channel_follow_list" :key="j">
                                            {{j+1}}. {{it.channel_name}} * {{it.follow_count}}
                                        </div>
                                    </a-col>
                                </a-row>

                                <a-row v-if="item.target_customer && item.target_customer.length>0">
                                    <a-col class="journal-item-bottom" :span="4">
                                        <span>靶子客户:</span>
                                    </a-col>
                                    <a-col :span="18">
                                        <div v-for="(it,j) in item.target_customer" :key="j">
                                            {{j+1}}. {{it.customer_name}}: {{it.customer_cellphone}}, {{it.customer_remark}}
                                        </div>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col class="journal-item-bottom" :span="4">
                                        <span v-if="item.category_id == 1">今日工作内容:</span> 
                                        <span v-if="item.category_id == 2">本周工作内容:</span> 
                                        <span v-if="item.category_id == 3">本月工作内容:</span> 
                                    </a-col>
                                    <a-col :span="18">
                                        <div style="white-space: pre-line" v-html="item.content"></div>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col class="journal-item-bottom" :span="4">
                                        <span v-if="item.category_id == 1">明日工作内容:</span> 
                                        <span v-if="item.category_id == 2">下周工作内容:</span> 
                                        <span v-if="item.category_id == 3">下月工作内容:</span> 
                                    </a-col>
                                    <a-col :span="18">
                                        <div style="white-space: pre-line" v-html="item.tomorrow"></div>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col class="journal-item-bottom" :span="4">
                                        遇到的问题: 
                                    </a-col>
                                    <a-col :span="18">
                                        <div style="white-space: pre-line" v-html="item.question"></div>
                                    </a-col>
                                </a-row>
                            </div>
                            <div v-if="item.images.length>0" class="journal-item-img journal-item-bottom">
                                <LImg preview="0" v-for="(image, index) of item.images" :key="index" :src="image" />
                            </div>
                            <div v-if="item.customer_ids.length>0">
                                <div class="journal-item-bottom">关联业务:</div>
                                <div @click.stop="showFollowModal(customer)" class="journal-item-customer" v-for="customer in item.customer_ids" :key='customer.customer_id'>
                                    <a-icon type="user" />  {{customer.customer_name}}
                                </div>
                            </div>
                            <div class="journal-item-more" v-if="item.permission.is_update || item.permission.is_delete">
                                <a-dropdown>
                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                        <a-icon type="ellipsis" />
                                    </a>
                                    <a-menu slot="overlay">
                                        <a-menu-item v-if="item.permission.is_update">
                                            <a @click="showEditModal(item)" href="javascript:;">编辑</a>
                                        </a-menu-item>
                                        <a-menu-item v-if="item.permission.is_delete">
                                            <a @click="toDelete(item,index)" href="javascript:;">删除</a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>


                            </div>
                            <div class="journal-comments-item">
                                <div v-for="(it,ind) in item.journal_comments" :key="it.commnet_id" class="journal-comments-wrap">
                                    <div class="journal-comments-wrap-nav">
                                        <img :src="it.commnet_avatar">
                                        <span>{{it.commnet_name}}</span>
                                    </div>
                                    <div class="journal-comments-wrap-content">
                                        <div @mouseover="hoverIndex = [index,ind,-1]" @mouseleave="hoverIndex = [-1,-1,-1]" >
                                            <span>{{it.commnet_content}}</span>
                                            <span class="journal-comments-item-icon" v-if="hoverIndex[0] == index && hoverIndex[1] == ind && hoverIndex[2] == -1">
                                                <a-icon @click="showAnswer(2,item,it)" class="journal-comments-item-hover" style="margin-left:8px" type="message" />
                                                <a-icon class="journal-comments-item-hover" style="margin-left:8px" type="delete" />
                                            </span>
                                        </div>
                                        <div @mouseover="hoverIndex = [index,ind,i]" @mouseleave="hoverIndex = [-1,-1,-1]" v-for="(con,i) in it.commnet_children" :key="con.commnet_id">
                                            <span>{{con.commnet_name}} 回复 {{it.commnet_name}}: {{con.commnet_content}}</span>
                                            <span class="journal-comments-item-icon" v-if="hoverIndex[0] == index && hoverIndex[1]  == ind && hoverIndex[2]  == i">
                                                <a-icon @click="showAnswer(3,item,it,con)" class="journal-comments-item-hover" style="margin-left:8px" type="message" />
                                                <a-icon class="journal-comments-item-hover" style="margin-left:8px" type="delete" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="journal-item-comments-answer">
                                <a-button size="small" @click="showAnswer(1,item)" type="primary">回复</a-button>
                            </div>
                            <div v-if="isAnswer" class="journal-item-comments">
                                <a-textarea v-model="answerText" placeholder="请输入" :rows="2" />
                                <div class="journal-item-comments-btn">
                                    <a-button size="small" @click="toComment(item)" type="primary">回复</a-button>
                                    <a-button @click="isAnswer = false" style="margin-left:5px" size="small">取消</a-button>
                                </div>
                            </div>
                        </div>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
            <commitRecord v-if="activeKey == 4" />
        </div>
        <editModal v-if="isEditModal" :item='modalData' />
        <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
    </div>
</template>

<script>
    import moment from 'moment'
    import C_ITEMS from '@/utils/items'
    import ranges from "@/common/mixins/ranges"
    import commitRecord from "./commitRecord"
    import infiniteScroll from 'vue-infinite-scroll'
    import editModal from '@/views/market/huidongApply/coachDaily/editModal'
    import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'journal',
        data() {
            return {
                activeKey:0,
                staffList: [],
                searchDatas:{},
                searchParams: {
                    "page": 0,
                    "per-page": 10,
                    "search": {
                        filter_type:''
                    },
                },
                journalType:C_ITEMS.journalType,
                list:[],
                myHeight:0,
                busy:false,
                loading:false,
                isEditModal:false,
                followVisible:false,
                modalData:null,
                leadsId:'',
                filter_type:['','unread','me','receive'],
                minHeight:250,
                authType:['crm','customer'],
                timers:'',
                hoverIndex:[-1,-1,-1],
                isAnswer:false,
                answerText:'',
                answerObj:{},
                answerNum:'',
            }
        },
        components: {
            editModal,
            followModal,
            commitRecord
        },
        mixins:[ranges],
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 320}px`
            }
        },
        directives: { infiniteScroll },
        created () {
            this.myHeight = this.windowHeight()
            this.getList()
            this.getStaff()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(){
                this.loading = true
                this.searchParams.search.filter_type = this.filter_type[this.activeKey]
                await this.$store.dispatch('oaIndexAction',this.searchParams)
                .then(res=>{
                    if(res.items.length >0){
                        this.list = [...this.list,...res.items]
                    }else{
                        this.busy = true
                    }
                    if(this.searchParams.page == 1){
                        if(this.list.length > 0){
                            this.timers = setTimeout(()=>{
                                this.$nextTick(()=>{
                                    if(this.$refs.journalItem){
                                        let arr = this.$refs.journalItem.$parent.$children
                                        arr.forEach((item,index)=>{
                                            if(item.$el.offsetTop < (Number(this.myHeight) - 450)){
                                                if(this.list[index].is_read === 0){
                                                    this.toSeen(index)
                                                }
                                            }
                                        })
                                    }
                                })
                            },2000)
                        }
                    }
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            changeTab(key) {
                clearTimeout(this.timers)
                this.activeKey = key
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.minHeight = 250
                this.getList()
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
                this.searchList()
            },
            searchList(){
                this.searchParams.page = 1
                this.minHeight = 250
                this.list = []
                this.busy = false
                this.getList()
            },
            handleInfiniteOnLoad(){
                if(this.searchParams.page != 0){
                    if(!this.busy){
                        this.getList()
                    }
                }
                this.searchParams.page++
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            showEditModal(item){
                this.modalData = item
                if(item){
                    let imgList = []
                    item.images.forEach((item,index)=>{
                        let signIndex = item.search(/cn/)
                        let sign = item.substring(signIndex + 3)
                        let obj = {
                            uid: `-${index+1}`,
                            name: `image${index+1}.png`,
                            status: 'done',
                            url:item,
                            type:'image/jpeg',
                            response:{
                                key:sign
                            }
                        }
                        imgList.push(obj)
                    })
                    item.imgList = imgList
                }
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.searchParams.page = 1
                    this.minHeight = 250
                    this.list = []
                    this.busy = false
                    this.getList()
                }
                this.isEditModal = false
            },
            toDelete(item,index){
                let that = this
                this.$confirm({
                    title: `您确定该日报？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('oaDeleteAction', { journal_id:item.journal_id })
                        .then(res=>{
                            that.$message.success('操作成功~')
                            that.list.splice(index,1)
                            resolve(res)
                        })
                        .catch(err=>{
                            that.$message.error('操作失败~')
                            resolve(res)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleScroll(e){
                if(e.target.scrollTop < this.minHeight) return false
                this.minHeight += 250
                let scrollTop = Number(e.target.scrollTop) + Number(e.target.offsetHeight)
                let arr = this.$refs.journalItem.$parent.$children
                let top = []
                arr.forEach((item,index)=>{
                    if(item.$el.offsetTop < scrollTop){
                        if(this.list[index].is_read === 0){
                            this.toSeen(index)
                        }
                    }
                })
            },
            async toSeen(index){
                await this.$store.dispatch('oaSeenAction', {journal_id:this.list[index].journal_id})
                .then(res=>{
                    this.list[index].is_read = 1
                })
            },
            showFollowModal(item) {
                this.modalData = item
                this.leadsId = item.customer_id
                this.followVisible = true
            },
            hideFollowModal() {
                this.followVisible = false
            },
            async toComment(item){
                if(!this.answerText){
                    this.$message.warning('请输入回复内容!!')
                    return false
                }
                this.answerObj.comment_content = this.answerText
                await this.$store.dispatch('oaAddCommentAction', this.answerObj)
                .then(res=>{
                    item.journal_comments = res.data.journal_comments
                    this.answerObj = {}
                    this.answerText = ''
                    this.isAnswer = false
                    this.$forceUpdate()
                })
            },
            showAnswer(num,item,it,con){
                let answerObj = {
                    journal_id:item.journal_id,
                    comment_entity:item.comment_entity,
                }
                if(num === 2){
                    answerObj.parentId = it.commnet_id
                }
                if(num === 3){
                    answerObj.parentId = con.commnet_id
                }
                this.answerObj = answerObj
                this.isAnswer = true
            }
        },
        beforeRouteLeave(to, from, next) {
            clearTimeout(this.timers)
            next()
        },
    }
</script>

<style lang="scss">
    .journal-modal{
        .ant-form-item{
            margin-bottom: 12px;
        }
    }
    .journal_main-box{
        width: 60%;
        padding: 0 15px;
        background: #fff;
    }
    .journal-box{
        overflow-y: scroll;
        .journal-item{
            border: 1px solid #e8e8e8;
            padding: 10px;
            width: 700px;
            border-radius: 3px;
            position: relative;
            .journal-item-title{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }
            .journal-item-img{
                img{
                    max-width: 80px;
                    max-height: 60px;
                    margin: 5px;
                }
            }
            .journal-item-customer{
                padding: 8px;
                background-color: #f5f7fa;
                border-radius: 2px;
                position: relative;
                margin-bottom: 5px;
                color: #3e84e9;
                cursor: pointer;
            }
            .journal-item-bottom{
                margin-bottom: 10px;
            }
            .journal-item-more{
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }
            .journal-item-comments{
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                .ant-input{
                    resize: none;
                    border: none;
                }
                .ant-input:focus{
                    box-shadow:none;
                }
                .journal-item-comments-btn{
                    text-align: right;
                    margin-right: 11px;
                    margin-bottom: 4px;
                }
            }
            .journal-comments-item{
                .journal-comments-wrap{
                    .journal-comments-wrap-nav{
                        display: flex;
                        align-items: center;
                        img{
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                    }
                    .journal-comments-item-icon{
                        cursor: pointer;
                    }
                    .journal-comments-item-hover:hover{
                        color: #00cca2;
                    }
                }
            }
            .journal-comments-wrap-content{
                padding: 15px 30px;
            }
            .journal-item-comments-answer{
                text-align: right;
                padding: 5px;
                background: #ccc;
            }
        }
        .ant-list-item{
            border: none;
            border-bottom: none !important;
        }
    }
    .journal-more{
        text-align: center;
        padding: 10px 0;
    }
</style>